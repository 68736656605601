import { TFunction } from 'react-i18next';
import { log } from '@core/logging/logging';
import { DASH_ENV } from '@utils/environment';
import { FreeConsultationNames, FreeConsultationsMap } from './booking.types';

export const WAITLIST_FORM_URL = process.env.NEXT_PUBLIC_FREE_CONSULTATION_WAITLIST_URL ?? '';

export const ONLINE_CLINIC = {
  id: process.env.NEXT_PUBLIC_CRM_ONLINE_CLINIC_ID,
  name: process.env.NEXT_PUBLIC_CRM_ONLINE_CLINIC_NAME,
};

export const BOOKING_CANCELLATION_PERIOD_HOURS = 48;

const lhrId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_LHR_TREATMENT_ID ?? '';
const awiId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_AWI_INJECTION_TREATMENT_ID ?? '';
const botoxId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_BOTOX_TREATMENT_ID ?? '';
const bodyId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_BODY_TREATMENT_ID ?? '';
const skinId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_SKIN_TREATMENT_ID ?? '';
const dermalId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_DERMAL_FILLERS_TREATMENT_ID ?? '';
const lipId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_LIP_FILLERS_TREATMENT_ID ?? '';
const morpheusId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_MORPHEUS8_TREATMENT_ID ?? '';
const coolsculptId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_COOLSCULPT_TREATMENT_ID ?? '';
const therascanId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_THERASCAN_TREATMENT_ID ?? '';
const emsellaId = process.env.NEXT_PUBLIC_FREE_CONSULTATION_EMSELLA_TREATMENT_ID ?? '';
const emsellaFreeTreatmentConsultationId =
  process.env.NEXT_PUBLIC_FREE_TREATMENT_EMSELLA_CONSULTATION_ID ?? '';
const clearAndBrilliantId =
  process.env.NEXT_PUBLIC_FREE_CONSULTATION_CLEAR_AND_BRILLIANT_TREATMENT_ID ?? '';

export const MAP_FREE_CONSULTATIONS = (
  t?: TFunction<'freeConsultation'>
): FreeConsultationsMap => ({
  laser: {
    name: t?.('treatments.laser.title') ?? '',
    description: t?.('treatments.laser.description') ?? '',
    treatmentId: lhrId,
    slug: 'laser',
  },
  injectables: {
    name: t?.('treatments.injectables.title') ?? '',
    description: t?.('treatments.injectables.description') ?? '',
    treatmentId: awiId,
    slug: 'injectables',
  },
  botox: {
    name: t?.('treatments.botox.title') ?? '',
    description: t?.('treatments.botox.description') ?? '',
    treatmentId: botoxId,
    slug: 'botox',
  },
  body: {
    name: t?.('treatments.body.title') ?? '',
    description: t?.('treatments.body.description') ?? '',
    treatmentId: bodyId,
    slug: 'body',
  },
  skin: {
    name: t?.('treatments.skin.title') ?? '',
    description: t?.('treatments.skin.description') ?? '',
    treatmentId: skinId,
    slug: 'skin',
  },
  dermalFillers: {
    name: t?.('treatments.dermalFillers.title') ?? '',
    description: t?.('treatments.dermalFillers.description') ?? '',
    treatmentId: dermalId,
    slug: 'dermal-fillers',
  },
  lipFillers: {
    name: t?.('treatments.lipFillers.title') ?? '',
    description: t?.('treatments.lipFillers.description') ?? '',
    treatmentId: lipId,
    slug: 'lip-fillers',
  },
  morpheus8: {
    name: t?.('treatments.morpheus8.title') ?? '',
    description: t?.('treatments.morpheus8.description') ?? '',
    treatmentId: morpheusId,
    slug: 'morpheus8',
  },
  coolsculpt: {
    name: t?.('treatments.coolsculpt.title') ?? '',
    description: t?.('treatments.coolsculpt.description') ?? '',
    treatmentId: coolsculptId,
    slug: 'coolsculpt',
  },
  therascan: {
    name: t?.('treatments.therascan.title') ?? '',
    description: t?.('treatments.therascan.description') ?? '',
    treatmentId: therascanId,
    slug: 'skin-scanner-consultation',
  },
  emsella: {
    name: t?.('treatments.emsella.title') ?? '',
    description: t?.('treatments.emsella.description') ?? '',
    treatmentId: emsellaId,
    slug: 'emsella-consultation',
  },
  emsellaFreeTreatment: {
    name: t?.('treatments.emsella.title') ?? '',
    description: t?.('treatments.emsella.description') ?? '',
    treatmentId: emsellaFreeTreatmentConsultationId,
    slug: 'emsella-consultation-free-treatment',
  },
  clearAndBrilliant: {
    name: t?.('treatments.clearAndBrilliant.title') ?? '',
    description: t?.('treatments.clearAndBrilliant.description') ?? '',
    treatmentId: clearAndBrilliantId,
    slug: 'clear-brilliant-consultation',
  },
});

export const getFreeConsultations = (t?: TFunction<'freeConsultation'>) => {
  const enabledFreeConsultations = t?.('enabledTreatments', { returnObjects: true }) ?? [];
  if (!enabledFreeConsultations.length) {
    log({
      category: 'booking-free-consultation',
      message: 'no_free_consultations_found',
      level: 'error',
    });
    if (DASH_ENV !== 'production') throw new Error('Empty free consultations in i18n');
  }
  const mapFreeConsultations = MAP_FREE_CONSULTATIONS(t);
  const freeConsultationsInfo = enabledFreeConsultations.map(
    (treatment) => mapFreeConsultations[treatment as FreeConsultationNames]
  );
  if (enabledFreeConsultations.length !== freeConsultationsInfo.length) {
    log({
      category: 'booking-free-consultation',
      message: 'missing_free_consultation_mapping',
      messageContext: {
        enabledFreeConsultations,
        freeConsultationsInfo,
      },
      level: 'warn',
    });
  }
  return freeConsultationsInfo;
};

export const getAllFreeConsultations = (t?: TFunction<'freeConsultation'>) =>
  Object.values(MAP_FREE_CONSULTATIONS(t));

export const getFreeConsultationBySlug = (slug: string, t?: TFunction<'freeConsultation'>) =>
  getAllFreeConsultations(t).find((f) => f.slug === slug);

export const getFreeConsultationByTreatmentId = (
  treatmentId: string,
  t?: TFunction<'freeConsultation'>
) => {
  const freeConsultations = getFreeConsultations(t);
  return freeConsultations.find((f) => f.treatmentId === treatmentId);
};
